<template>
    <div class="version-index">
        <md-tabs ref="tabs" @change="tabChange">
            <md-tab md-label="Versions" :md-active="tabIndex==0">
                <version-list ref="list" v-if="tabIndex==0"></version-list>
            </md-tab>
            <md-tab md-label="Add Version" v-if="hasRole('admin')" :md-active="tabIndex==1">
                <version-edit ref="add" v-if="tabIndex==1"></version-edit>
            </md-tab>
            <md-tab md-label="Edit Version" v-if="tabIndex==2" :md-active="tabIndex==2">
                <version-edit ref="edit" :id="id" v-if="tabIndex==2"></version-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>


<script>
import VersionList from "@/components/Version/VersionList";
import VersionEdit from "@/components/Version/VersionEdit";
import { mapGetters } from "vuex";
export default {
    props: ["id"],
    components: {
        VersionList,
        VersionEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/versions");
            }
            if (index == 1) {
                this.$router.push("/admin/versions/new");
            }
            if (index == 2) {
                this.$router.push(`/admin/versions/${this.id}`);
            }
        }
    }
};
</script>