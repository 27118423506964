<template>
    <div class="version-list">
        <list :fs="versionFields" api="versions" :editLink="editLink" :addLink="addLink" :showAction="showAction" title="Versions"></list>
    </div>
</template>
<script>
import List from "@/components/List";
import { versionFields } from "@/config";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        }),
        versionFields() {
            return versionFields;
        },
        editLink() {
            return this.hasRole("admin") ? "/admin/versions" : "";
        },
        addLink() {
            return this.hasRole("admin") ? "/admin/versions/new" : "";
        },
        showAction() {
            return this.hasRole("admin");
        }
    }
};
</script>