import { render, staticRenderFns } from "./VersionList.vue?vue&type=template&id=212375b0&"
import script from "./VersionList.vue?vue&type=script&lang=js&"
export * from "./VersionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports