<template>
    <div class="version-edit">
        <edit :fs="versionFields" api="versions" :id="id" title="Version" @saved="saved"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { versionFields } from "@/config";
export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        versionFields() {
            return versionFields;
        }
    },
    methods: {
        saved() {
            this.$router.push("/admin/versions");
        }
    }
};
</script>